import React, {useEffect, useState} from "react";
import {Routes, Route, useNavigate} from "react-router-dom";
import LandingPage from "./pages/landingPage/LandingPage";
import Header from "./components/header/Header";
import {
  HOME,
  TRANSCRIBE,
  SIGNIN,
  SIGNUP,
  KYC,
  DASHBOARD,
  ENTITY_RECOGNITION,
  ACTION_AREA,
  MOBILE_MAX_SIZE,
  SENTIMENT_ANALYSIS,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  RESET_PASSWORD_CODE,
  FORGOT_PASSWORD_EMAIL_SENT,
  RESET_PASSWORD_SUCCESS,
  BILLING,
  BILLING_PROCESS,
  STRIPE_PLANS_PAGE,
} from "./utils/constants";
import Footer from "./components/footer/Footer";
import Transcribe from "./pages/transcribe/Transcribe";
import {useGlobalDispatch, useGlobalState} from "./helpers/globalContext";
import {
  fetchPlaygroundSessionToken,
  createClientToken,
  handleSocialCallback,
} from "./helpers/api/auth";
import {handleGetKYCStatus} from "./helpers/api/kyc";
import {deleteCookie, setCookie, getCookie} from "./helpers/cookie";
import {
  getEnableBilling,
  getCustomerCurrentInvoice,
  getCustomerInvoices,
  getCustomerPaymentMethods,
  getCustomerSubscriptions,
  getProductPlans,
} from "./helpers/api/billing";
import Login from "./pages/auth/login/Login";
import SignUp from "./pages/signUp/SignUp";
import KYC_PAGE from "./pages/kycPage/KYCPage";
import ApiDashboard from "./pages/apiDashboard/ApiDashboard";
import {getCustomerAPIKeys} from "./helpers/api/auth";
import EntityRecognition from "./pages/entityRecognition/EntityRecognition";
import SentimentAnalyis from "./pages/sentimentAnalysis/SentimentAnalysis";
import ProgressBar from "./components/progressBar/ProgressBar";
import VerticalBrandLogo from "./icons/brand-logo-vertical.svg";
import CustomModal from "./components/customModal/CustomModal";
import {termsAndConditionsModalText} from "./cms/generalCMS";
import {globalTheme} from "./styles/global-style";
import {ThemeProvider} from "@mui/material";
import {AuthLayout} from "./components/layouts";
import CookieBanner from "./components/cookieBanner/CookieBanner";
import {
  ForgotPassword,
  ForgotPasswordEmailSent,
  ResetPassword,
  ResetPasswordCode,
  ResetPasswordSuccess,
} from "./pages/auth";
import BillingPageWithEffect from "./pages/billingPage/BillingPage";
import SubscribePageWithEffect from "./pages/billingPage/subscribePage/SubscribePage";
import StripePlansPage from "./pages/billingPage/stripePlansPage/StripePlansPage";

const Main = () => {
  const navigate = useNavigate();
  const dispatch = useGlobalDispatch();
  const globalState = useGlobalState();

  const [processProgress, setProcessProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  let {
    sessionToken,
    playgroundSession,
    userApiKeys,
    enableBilling,
    currentUserInvoice,
    userInvoices,
    userPaymentMethods,
    userSubscriptions,
    productPlans,
  } = globalState;
  const {errorDetails, showTermsModal} = globalState;
  let cookie_consent = globalState.cookieConsent;

  useEffect(() => {
      const checkLogin = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const errorParams = urlParams.get("error");

        // check if user session token is still valid
        let sessionToken = getCookie("sessionToken");
        let sessionExpired = sessionToken === "" || sessionToken === null;

        if (!sessionExpired) {
          return;
        } else if (errorParams !== null) {
          dispatch({
            type: "UPDATE_ERROR_MESSAGE",
            payload: {
              errorMessage: `${errorParams}, please try again.`,
              failureArea: ACTION_AREA.LOGIN,
            },
          });

          navigate(SIGNIN);
          return;
        } else if (
          window.localStorage.getItem("providerState") &&
          window.localStorage.getItem("provider") &&
          urlParams.get("code") &&
          sessionExpired
        ) {
          setLoading(true);
          const codeParam = urlParams.get("code");

          const provider = window.localStorage.getItem("provider");
          const providerState = window.localStorage.getItem("providerState");
          window.localStorage.removeItem("providerState");
          window.localStorage.removeItem("provider");

          const totalSteps = 80;
          let currentStep = processProgress;

          const simulateProgress = () => {
            const progressInterval = setInterval(() => {
              currentStep += 20;
              if (currentStep >= totalSteps) {
                clearInterval(progressInterval);
              } else {
                setProcessProgress(currentStep);
              }
            }, 200);
          };

          simulateProgress();

          await handleSocialCallback(
            provider,
            codeParam,
            providerState,
            dispatch
          ).then((resp) => {
            if (resp !== undefined) {
              setProcessProgress(100);

              if (resp !== null) {
                // Set expiry date to 6 months from now
                setCookie("sessionToken", resp.access_token, 180);
                deleteCookie("requestClientToken"); // remove client token since it would be playground client token
                dispatch({
                  type: "UPDATE_ENABLE_BILLING",
                  payload: {enable: false, error: false, fetched: false},
                });

                setLoading(false);

                handleGetKYCStatus(resp.access_token, dispatch).then(
                  (kycRedirect) => {
                    if (kycRedirect) {
                      navigate(KYC);
                    } else {
                      navigate(HOME);
                    }
                  }
                );
              } else {
                setLoading(false);
              }
            }
          });
          return;
        }
        let pathName = window.location.pathname;
        if (sessionExpired && pathName.includes(DASHBOARD)) {
          navigate(HOME);
        }
      };

      const getSessionToken = async () => {
        // check if user is logged in first before fetching playground session token
        const sessionToken = getCookie("sessionToken");
        if (sessionToken) {
          dispatch({
            type: "UPDATE_SESSION_TOKEN",
            payload: sessionToken,
          });
          return sessionToken;
        }
        return null;
      };

      const checkMobileScreen = async () => {
        const mobileCheck = window.screen.width <= MOBILE_MAX_SIZE;
        dispatch({
          type: "UPDATE_IS_MOBILE",
          payload: mobileCheck,
        });
      };

      const handlePlaygroundSession = async () => {
        try {
          if (!playgroundSession.fetched) {
            handleGlobalStateUpdate({
              type: "UPDATE_PLAYGROUND_SESSION_TOKEN",
              payload: {data: "", fetched: true},
            });

            const playgroundSessionData = await fetchPlaygroundSessionToken();

            handleGlobalStateUpdate({
              type: "UPDATE_PLAYGROUND_SESSION_TOKEN",
              payload: playgroundSessionData,
            });

            return playgroundSessionData;
          }

          return playgroundSession;
        } catch (error) {
          const sessionData = {data: "", fetched: true};
          handleGlobalStateUpdate({
            type: "UPDATE_PLAYGROUND_SESSION_TOKEN",
            payload: sessionData,
          });
          return sessionData;
        }
      }

      const handleRequestClientToken = async (sessionData) => {
        let tokenObject = getCookie("requestClientToken");
        let isTokenExpired = true;
        let requestClientToken = null;

        if (tokenObject) {
          requestClientToken = JSON.parse(tokenObject);
          if (requestClientToken && (requestClientToken?.token !=='' || requestClientToken?.token !==null) ) {
            isTokenExpired = Date.parse(requestClientToken.expiration_time) < Date.now();
          }
        }

        if (isTokenExpired || !requestClientToken?.fetched) {
          handleGlobalStateUpdate({
            type: "UPDATE_REQUEST_CLIENT_TOKEN",
            payload: {
              id: 0,
              token: "",
              expiration_time: "",
              fetched: true,
              error: false,
            },
          });

          const newRequestClientToken = await createClientToken(
            sessionData.data,
            dispatch,
            true
          );

          setCookie("requestClientToken", JSON.stringify(newRequestClientToken), 1);

          return newRequestClientToken;
        }

        return requestClientToken;
      }

      const initializeApp = async () => {
        try {
          await checkMobileScreen();
          const sessionToken = await getSessionToken();
          let sessionData;
          if (sessionToken) {
            sessionData = {
              data: sessionToken,
              fetched: true
            }
          } else {
            sessionData = await handlePlaygroundSession(sessionToken);
          }

          if (sessionData.data !== "" || sessionData.data !== null) {
            const clientToken = await handleRequestClientToken(sessionData);

            if (clientToken) {
              handleGlobalStateUpdate({
                type: "UPDATE_REQUEST_CLIENT_TOKEN",
                payload: clientToken,
              });
            }
          }
        } catch (error) {
          console.error("Failed to initialize app:", error);
          // Handle error as needed
        }
      };

      const handleGetUserApiKeys = async () => {
        if (sessionToken === null || sessionToken === "") {
          return;
        }

        if (
          errorDetails.errorMessage === "" &&
          errorDetails.failureArea !== ACTION_AREA.DASHBOARD &&
          !userApiKeys.fetched
        ) {
          dispatch({
            type: "UPDATE_USER_API_KEYS",
            payload: {data: [], fetched: true},
          });
          await getCustomerAPIKeys(sessionToken, dispatch).then((resp) => {
            let sortedApiKeys;
            sortedApiKeys = [...resp].sort((a, b) => {
              return (
                Date.parse(b.expiration_time) - Date.parse(a.expiration_time)
              );
            });
            if (sortedApiKeys !== null) {
              dispatch({
                type: "UPDATE_USER_API_KEYS",
                payload: {data: sortedApiKeys, fetched: true},
              });
            }
          });
        }
      };

      function handleResize() {
        const mobileCheck = window.screen.width <= MOBILE_MAX_SIZE;
        dispatch({
          type: "UPDATE_IS_MOBILE",
          payload: mobileCheck,
        });
      }

      const checkBillingEnabled = async () => {
        if (sessionToken === null || sessionToken === "") {
          return;
        }

        if (!enableBilling.fetched && !enableBilling.error) {
          dispatch({
            type: "UPDATE_ENABLE_BILLING",
            payload: {enable: false, error: false, fetched: true},
          });
          await getEnableBilling(sessionToken, dispatch, 1).then((response) => {
            dispatch({
              type: "UPDATE_ENABLE_BILLING",
              payload: response,
            });
          });
        }
      };

      const handleGettingBillingData = async () => {
        if (sessionToken === null || sessionToken === "") {
          return;
        }

        // call all billing related functions here
        // call them based on importance, billing page data should be called first and then calls ended for subcribe page after

        if (!currentUserInvoice.fetched) {
          await handleGetProductPlans();
          await handleGetCustomerInvoice();
          await handleGetCustomerInvoices();
          await handleGetCustomerPaymentMethods();
          await handleGetCustomerSubscriptions();
        }
      };

      const handleGetCustomerInvoice = async () => {
        if (sessionToken === null || sessionToken === "") {
          return;
        }

        if (
          !currentUserInvoice.data &&
          !currentUserInvoice.error &&
          !currentUserInvoice.fetched
        ) {
          dispatch({
            type: "UPDATE_CURRENT_USER_INVOICE",
            payload: {data: [], error: "", fetched: true},
          });
          await getCustomerCurrentInvoice(sessionToken, dispatch).then(
            (response) => {
              if (response !== null) {
                dispatch({
                  type: "UPDATE_CURRENT_USER_INVOICE",
                  payload: {
                    data: response.data,
                    error: response.error,
                    fetched: true,
                  },
                });
              }
            }
          );
        }
      };

      const handleGetCustomerInvoices = async () => {
        if (sessionToken === null || sessionToken === "") {
          return;
        }

        if (
          userInvoices.data.length === 0 &&
          !userInvoices.error &&
          !userInvoices.fetched
        ) {
          dispatch({
            type: "UPDATE_USER_INVOICES",
            payload: {data: [], error: false, fetched: true},
          });
          await getCustomerInvoices(sessionToken, dispatch).then((response) => {
            if (response !== null) {
              dispatch({
                type: "UPDATE_USER_INVOICES",
                payload: response,
              });
            }
          });
        }
      };

      const handleGetCustomerPaymentMethods = async () => {
        if (sessionToken === null || sessionToken === "") {
          return;
        }

        if (
          userPaymentMethods.data.length === 0 &&
          !userPaymentMethods.error &&
          !userPaymentMethods.fetched
        ) {
          dispatch({
            type: "UPDATE_USER_PAYMENT_METHODS",
            payload: {data: [], error: false, fetched: true},
          });
          await getCustomerPaymentMethods(sessionToken, dispatch).then((data) => {
            if (data !== null) {
              dispatch({
                type: "UPDATE_USER_PAYMENT_METHODS",
                payload: data,
              });
            }
          });
        }
      };

      const handleGetCustomerSubscriptions = async () => {
        if (sessionToken === null || sessionToken === "") {
          return;
        }

        if (
          userSubscriptions.data.length === 0 &&
          !userSubscriptions.error &&
          !userSubscriptions.fetched
        ) {
          dispatch({
            type: "UPDATE_USER_SUBSCRIPTIONS",
            payload: {data: [], error: false, fetched: true},
          });
          await getCustomerSubscriptions(sessionToken, dispatch).then((data) => {
            if (data !== null) {
              dispatch({
                type: "UPDATE_USER_SUBSCRIPTIONS",
                payload: data,
              });
            }
          });
        }
      };

      const handleGetProductPlans = async () => {
        if (sessionToken === null || sessionToken === "") {
          return;
        }

        if (
          productPlans.data === null &&
          !productPlans.error &&
          !productPlans.fetched
        ) {
          dispatch({
            type: "UPDATE_PRODUCT_PLANS",
            payload: {data: null, error: false, fetched: true},
          });
          await getProductPlans(sessionToken, dispatch).then((data) => {
            dispatch({
              type: "UPDATE_PRODUCT_PLANS",
              payload: data,
            });
          });
        }
      };

      window.addEventListener("resize", handleResize);
      checkLogin();
      initializeApp();
      handleGetUserApiKeys();
      checkBillingEnabled();
      if (enableBilling.enable) {
        handleGettingBillingData();
      }
    }, [
      dispatch,
      navigate,
      sessionToken,
      userApiKeys,
      errorDetails.errorMessage,
      errorDetails.failureArea,
      processProgress,
      playgroundSession,
      enableBilling,
      currentUserInvoice,
      userInvoices,
      userPaymentMethods,
      userSubscriptions,
      productPlans,
    ]
  );

  const handleGlobalStateUpdate = (dispatchState) => {
    dispatch({
      ...dispatchState
    })
  }
  return (
    <ThemeProvider theme={globalTheme}>
      <Header
        globalState={globalState}
        navigate={navigate}
        dispatch={dispatch}
      />

      {loading ? (
        <div className="main-loader-complete-container">
          <img src={VerticalBrandLogo} alt="vertical brand logo"/>
          <div className="main-progress-bar">
            <ProgressBar processProgress={processProgress}/>
          </div>
        </div>
      ) : (
        <>
          {showTermsModal.show && (
            <CustomModal
              show={showTermsModal.show}
              modalText={termsAndConditionsModalText}
              productNavigation={showTermsModal.navigation}
              navigate={navigate}
              dispatch={dispatch}
            />
          )}
          <Routes>
            <Route
              path={HOME}
              element={
                <LandingPage
                  globalState={globalState}
                  navigate={navigate}
                  dispatch={dispatch}
                />
              }
            />
            <Route
              path={TRANSCRIBE}
              element={
                <Transcribe
                  globalState={globalState}
                  dispatch={dispatch}
                  navigate={navigate}
                />
              }
            />
            <Route path="/" element={<AuthLayout/>}>
              <Route
                index
                path={SIGNIN}
                element={
                  <Login
                    navigate={navigate}
                    dispatch={dispatch}
                    globalState={globalState}
                  />
                }
              />
              <Route
                path={SIGNUP}
                element={
                  <SignUp
                    dispatch={dispatch}
                    globalState={globalState}
                    navigate={navigate}
                  />
                }
              />

              <Route
                path={FORGOT_PASSWORD}
                element={
                  <ForgotPassword
                    dispatch={dispatch}
                    globalState={globalState}
                    navigate={navigate}
                  />
                }
              />

              <Route
                path={FORGOT_PASSWORD_EMAIL_SENT}
                element={
                  <ForgotPasswordEmailSent
                    dispatch={dispatch}
                    globalState={globalState}
                    navigate={navigate}
                  />
                }
              />
              <Route
                path={RESET_PASSWORD_CODE}
                element={
                  <ResetPasswordCode
                    dispatch={dispatch}
                    globalState={globalState}
                    navigate={navigate}
                  />
                }
              />

              <Route
                path={RESET_PASSWORD}
                element={
                  <ResetPassword
                    dispatch={dispatch}
                    globalState={globalState}
                    navigate={navigate}
                  />
                }
              />
              <Route
                path={RESET_PASSWORD_SUCCESS}
                element={
                  <ResetPasswordSuccess
                    dispatch={dispatch}
                    globalState={globalState}
                    navigate={navigate}
                  />
                }
              />
            </Route>
            <Route
              path={KYC}
              element={<KYC_PAGE navigate={navigate} dispatch={dispatch}/>}
            />
            <Route
              path={DASHBOARD}
              element={
                <ApiDashboard
                  navigate={navigate}
                  globalState={globalState}
                  dispatch={dispatch}
                />
              }
            />
            <Route
              path={ENTITY_RECOGNITION}
              element={
                <EntityRecognition
                  navigate={navigate}
                  globalState={globalState}
                  dispatch={dispatch}
                />
              }
            />
            <Route
              path={SENTIMENT_ANALYSIS}
              element={
                <SentimentAnalyis
                  navigate={navigate}
                  globalState={globalState}
                  dispatch={dispatch}
                />
              }
            />
            <Route
              path={BILLING}
              element={
                <BillingPageWithEffect
                  navigate={navigate}
                  dispatch={dispatch}
                  globalState={globalState}
                />
              }
            />
            <Route
              path={BILLING_PROCESS}
              element={
                <SubscribePageWithEffect
                  globalState={globalState}
                  dispatch={dispatch}
                  navigate={navigate}
                />
              }
            />
            <Route
              path={STRIPE_PLANS_PAGE}
              element={
                <StripePlansPage
                  globalState={globalState}
                  dispatch={dispatch}
                  navigate={navigate}
                />
              }
            />
          </Routes>
          <Footer globalState={globalState}/>
        </>
      )}

      {window.location.pathname !== SIGNIN &&
        window.location.pathname !== SIGNUP &&
        window.location.pathname !== FORGOT_PASSWORD &&
        window.location.pathname !== RESET_PASSWORD &&
        !cookie_consent && (
          <CookieBanner globalState={globalState} dispatch={dispatch}/>
        )}
    </ThemeProvider>
  );
};

export default Main;
